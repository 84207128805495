import { GridLines } from "@components/atoms/GridLines"
import { Icon } from "@components/atoms/Icons"
import { Logo } from "@components/atoms/Logo"
import { Menu } from "@components/atoms/Menu"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"
import { Link } from "eddev/routing"

export function Footer({ scheme }: { scheme: "light" | "dark" | "bold" }) {
  const { addRef } = useAdjustedHeight()

  return (
    <div className={`subtheme-${scheme} w-full relative footer`}>
      <div className="bg-bg">
        <GridLines />
        <div className="grid-auto items-center relative z-10 lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 h-[80px] order-1" />

          <div
            ref={(el) => el && addRef(el)}
            className="col-span-12 py-8 md:col-span-6 flex items-center justify-center inset-bg min-h-full order-4 md:order-2"
          >
            <i className="w-full px-9 text-text">
              <Logo />
            </i>
          </div>

          <div
            ref={(el) => el && addRef(el)}
            className="col-span-12 md:col-span-6 flex flex-col justify-center gap-7 py-8 px-7 inset-bg h-full order-2 md:order-3"
          >
            <div className="flex gap-3">
              <Icon name="ABORIGINAL" className="rounded-sm overflow-hidden" />
              <Icon name="TORRESSTRAIT" className="rounded-sm overflow-hidden" />
            </div>
            <p className="type-body-xs text-textAlt">
              Sumday is headquartered in Tasmania. An island that produces more renewable energy than its people consume
              and one with a story that began over 60,000 years ago. Sumday acknowledges the ancient history of the
              Tasmanian Aboriginal people as the First People of lutruwita/Tasmania. Tasmanian Aboriginal people’s
              culture and language have been and continue to be based on a deep and continuous connection to family,
              community, the land, sea, and waterways – a connection we respect and continue to be inspired by in all
              that we do.
            </p>
          </div>

          <div ref={(el) => el && addRef(el)} className="col-span-12 order-3 md:order-4">
            <nav className="footer-nav h-full">
              <Menu location="Footer" />
            </nav>
          </div>

          <div
            ref={(el) => el && addRef(el)}
            className="col-span-12 md:col-span-8 flex gap-10 items-center p-7 inset-bg h-full order-5"
          >
            <span className="hidden md:block type-label-l text-text">&copy; Sumday 2024</span>
            <nav className="legal-nav flex">
              <div
                className="!visible !bg-transparent flex items-center pr-10"
                data-cky-tag="revisit-consent"
                data-tooltip="Consent Preferences"
              >
                <a
                  className="cky-btn-revisit type-label-m text-textAlt hover:underline cursor-pointer"
                  aria-label="Consent Preferences"
                >
                  Cookies
                </a>
              </div>
              <Menu location="Legal" />
            </nav>
          </div>

          <div className="hidden md:block col-span-2 py-7 h-full inset-bg md:order-6" />

          <div
            ref={(el) => el && addRef(el)}
            className="col-span-6 flex md:hidden items-center h-full p-7 inset-bg border-ruler border-l-[0.125px] border-t-0 border-r-0 border-b-0 order-6"
          >
            <span className="type-label-l text-text">&copy; Sumday 2024</span>
          </div>

          <div className="col-span-6 md:col-span-2 flex justify-end md:justify-start items-center h-full p-7 inset-bg order-7">
            <Link
              className="type-label-m text-textAlt hover:underline"
              href="https://ed.studio/?utm_source=sumday.com&utm_medium=website&utm_campaign=Site_by_ED"
              target="_blank"
            >
              Site by ED.
            </Link>
          </div>

          <div className="col-span-12 h-[80px] order-8" />
        </div>
      </div>
    </div>
  )
}

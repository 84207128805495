import React, { useEffect, useState } from "react"
import { Link } from "eddev/routing"
import { useAppData } from "eddev/hooks"
import { MenuItemFieldsFragment, MenuLocationEnum } from "../../types.graphql"
import { Icon } from "./Icons"

/**
 * Feel free to adjust this file to meet your needs! Keep in mind that you shouldn't
 * add any styling to this file, as it's meant to be a generic component.
 *
 * To style a menu, use styled(Menu), and target the ul, li and a individually.
 */

type MenuItemsProps = {
  items: MenuItemFieldsFragment[]
  className?: string
  depth: number
}

function MenuItems(props: MenuItemsProps) {
  if (props.items) {
    return (
      <ul data-menu-depth={props.depth}>
        {props.items.map((item, i) => (
          <li key={item.label} className={item.cssClasses?.length ? item.cssClasses.join(" ") : undefined}>
            <Link
              href={item.url!}
              target={item.target!}
              className={`${item?.description ? `flex flex-col gap-4 w-full items-start justify-start text-left has-desc` : `flex no-desc`} ${item.childItems?.nodes?.length! > 0 ? `has-children` : ``}`}
            >
              <span className="w-full text-left flex items-center gap-3">
                {item.label!.split("<br>").map((part, index) => (
                  <React.Fragment key={index}>
                    {part}
                    {index < item.label?.split("<br>").length! - 1 && <br />}
                  </React.Fragment>
                ))}
                {item.label?.toLowerCase() === "youtube" && <Icon className="h-4 w-4" name="YOUTUBE" />}
                {item.label?.toLowerCase() === "linkedin" && <Icon className="h-4 w-4" name="LINKEDIN" />}
                {item.label?.toLowerCase() === "instagram" && <Icon className="h-4 w-4" name="INSTAGRAM" />}
              </span>

              {item?.description && (
                <p
                  className="w-full text-left type-body-m text-textAlt"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              )}
            </Link>
            {item.childItems?.nodes?.length! > 0 && (
              <MenuItems depth={props.depth + 1} items={item.childItems!.nodes as MenuItemFieldsFragment[]} />
            )}
          </li>
        ))}
      </ul>
    )
  }
  return null
}

function MobileMenuItems(props: MenuItemsProps) {
  if (props.items) {
    return (
      <ul data-menu-depth={props.depth}>
        {props.items.map((item, i) => {
          const [open, setOpen] = useState<boolean>(false)

          return (
            <li key={item.label} className={item.cssClasses?.length ? item.cssClasses.join(" ") : undefined}>
              {item.childItems?.nodes?.length! > 0 ? (
                <>
                  <div className={open ? `open` : ``} onClick={() => setOpen(!open)}>
                    {item.label} <Icon name="CHEVRON_DOWN" />
                  </div>
                  <MenuItems depth={props.depth + 1} items={item.childItems!.nodes as MenuItemFieldsFragment[]} />
                </>
              ) : (
                <>
                  <Link href={item.url!} target={item.target!}>
                    <span>{item.label}</span>
                    {item.label?.toLowerCase() === "youtube" && <Icon className="h-4 w-4" name="YOUTUBE" />}
                    {item.label?.toLowerCase() === "linkedin" && <Icon className="h-4 w-4" name="LINKEDIN" />}
                    {item.label?.toLowerCase() === "instagram" && <Icon className="h-4 w-4" name="INSTAGRAM" />}
                  </Link>
                </>
              )}
            </li>
          )
        })}
      </ul>
    )
  }
  return null
}

type Props = {
  location: keyof typeof MenuLocationEnum
  className?: string
  mobile?: boolean
}

export function Menu(props: Props) {
  const menus = useAppData((appData) => appData.menus?.nodes)
  const menu = menus?.find((menu) => menu?.locations?.includes(MenuLocationEnum[props.location]))
  if (env.dev) {
    useEffect(() => {
      if (!menu) {
        console.error(`No menu for location "${props.location}".`)
      }
    }, [menu, props.location])
  }

  if (menu?.menuItems?.nodes) {
    if (props.mobile) {
      return (
        <MobileMenuItems
          className={props.className}
          depth={0}
          items={menu.menuItems.nodes.filter((p) => !p.parentId) as MenuItemFieldsFragment[]}
        />
      )
    } else {
      return (
        <MenuItems
          className={props.className}
          depth={0}
          items={menu.menuItems.nodes.filter((p) => !p.parentId) as MenuItemFieldsFragment[]}
        />
      )
    }
  } else {
    return null
  }
}

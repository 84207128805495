import _app from "../../../views/_app.tsx";

import { viewManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
const manifest = {
  "404": dynamic(() => import("../../../views/404.tsx"), "views/404.tsx"),
  "_app": _app,
  "archive-blog-post": dynamic(() => import("../../../views/archive-blog-post.tsx"), "views/archive-blog-post.tsx"),
  "front-page": dynamic(() => import("../../../views/front-page.tsx"), "views/front-page.tsx"),
  "page": dynamic(() => import("../../../views/page.tsx"), "views/page.tsx"),
  "single": dynamic(() => import("../../../views/single.tsx"), "views/single.tsx"),
  "single-accountants": dynamic(() => import("../../../views/single-accountants.tsx"), "views/single-accountants.tsx"),
  "single-blog-post": dynamic(() => import("../../../views/single-blog-post.tsx"), "views/single-blog-post.tsx"),
  "single-event": dynamic(() => import("../../../views/single-event.tsx"), "views/single-event.tsx"),
  "single-feature": dynamic(() => import("../../../views/single-feature.tsx"), "views/single-feature.tsx"),
  "taxonomy-accountants-industries": dynamic(
    () => import("../../../views/taxonomy-accountants-industries.tsx"),
    "views/taxonomy-accountants-industries.tsx",
  ),
  "taxonomy-blog-post-category": dynamic(
    () => import("../../../views/taxonomy-blog-post-category.tsx"),
    "views/taxonomy-blog-post-category.tsx",
  ),
  "taxonomy-event-category": dynamic(
    () => import("../../../views/taxonomy-event-category.tsx"),
    "views/taxonomy-event-category.tsx",
  ),
  "templates/bold": dynamic(() => import("../../../views/templates/bold.tsx"), "views/templates/bold.tsx"),
  "templates/dark": dynamic(() => import("../../../views/templates/dark.tsx"), "views/templates/dark.tsx"),
  "templates/light": dynamic(() => import("../../../views/templates/light.tsx"), "views/templates/light.tsx"),
  "templates/text-page": dynamic(
    () => import("../../../views/templates/text-page.tsx"),
    "views/templates/text-page.tsx",
  ),
};

viewManifestReader.value = manifest;

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}

import ICONS from "../../assets/icons/icons.json"

export const Icon = ({ name, className }: { name: string; className?: string }) => {
  const icon = ICONS.find((icon) => icon.name === name)
  if (!icon) return <></>

  return (
    <i className={`flex justify-center items-center ${className}`} dangerouslySetInnerHTML={{ __html: icon.svg }} />
  )
}

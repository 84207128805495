import { blockManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
import core from "../../../blocks/_core";

const manifest = {
  "acf/accountants-carbon-accountant-certification": dynamic(
    () => import("../../../blocks/accountants/carbon-accountant-certification.tsx"),
    "blocks/accountants/carbon-accountant-certification.tsx",
  ),
  "acf/accountants-carbon-accountant-contact": dynamic(
    () => import("../../../blocks/accountants/carbon-accountant-contact.tsx"),
    "blocks/accountants/carbon-accountant-contact.tsx",
  ),
  "acf/accountants-carbon-accountant-filter-results": dynamic(
    () => import("../../../blocks/accountants/carbon-accountant-filter-results.tsx"),
    "blocks/accountants/carbon-accountant-filter-results.tsx",
  ),
  "acf/accountants-carbon-accountant-header": dynamic(
    () => import("../../../blocks/accountants/carbon-accountant-header.tsx"),
    "blocks/accountants/carbon-accountant-header.tsx",
  ),
  "acf/accountants-carbon-accountant-intro": dynamic(
    () => import("../../../blocks/accountants/carbon-accountant-intro.tsx"),
    "blocks/accountants/carbon-accountant-intro.tsx",
  ),
  "acf/accountants-carbon-accountant-services": dynamic(
    () => import("../../../blocks/accountants/carbon-accountant-services.tsx"),
    "blocks/accountants/carbon-accountant-services.tsx",
  ),
  "acf/blog-blog-archive-hero": dynamic(
    () => import("../../../blocks/blog/blog-archive-hero.tsx"),
    "blocks/blog/blog-archive-hero.tsx",
  ),
  "acf/blog-blog-featured-posts": dynamic(
    () => import("../../../blocks/blog/blog-featured-posts.tsx"),
    "blocks/blog/blog-featured-posts.tsx",
  ),
  "acf/blog-blog-post-content": dynamic(
    () => import("../../../blocks/blog/blog-post-content.tsx"),
    "blocks/blog/blog-post-content.tsx",
  ),
  "acf/blog-blog-post-details": dynamic(
    () => import("../../../blocks/blog/blog-post-details.tsx"),
    "blocks/blog/blog-post-details.tsx",
  ),
  "acf/content-advisors-ad": dynamic(
    () => import("../../../blocks/content/advisors-ad.tsx"),
    "blocks/content/advisors-ad.tsx",
  ),
  "acf/content-anchor": dynamic(() => import("../../../blocks/content/anchor.tsx"), "blocks/content/anchor.tsx"),
  "acf/content-anchor-button-group": dynamic(
    () => import("../../../blocks/content/anchor-button-group.tsx"),
    "blocks/content/anchor-button-group.tsx",
  ),
  "acf/content-animated-title": dynamic(
    () => import("../../../blocks/content/animated-title.tsx"),
    "blocks/content/animated-title.tsx",
  ),
  "acf/content-averages-actuals": dynamic(
    () => import("../../../blocks/content/averages-actuals.tsx"),
    "blocks/content/averages-actuals.tsx",
  ),
  "acf/content-banner": dynamic(() => import("../../../blocks/content/banner.tsx"), "blocks/content/banner.tsx"),
  "acf/content-card-carousel": dynamic(
    () => import("../../../blocks/content/card-carousel.tsx"),
    "blocks/content/card-carousel.tsx",
  ),
  "acf/content-card-image": dynamic(
    () => import("../../../blocks/content/card-image.tsx"),
    "blocks/content/card-image.tsx",
  ),
  "acf/content-careers": dynamic(() => import("../../../blocks/content/careers.tsx"), "blocks/content/careers.tsx"),
  "acf/content-chapters": dynamic(() => import("../../../blocks/content/chapters.tsx"), "blocks/content/chapters.tsx"),
  "acf/content-curated-gallery": dynamic(
    () => import("../../../blocks/content/curated-gallery.tsx"),
    "blocks/content/curated-gallery.tsx",
  ),
  "acf/content-faq": dynamic(() => import("../../../blocks/content/faq.tsx"), "blocks/content/faq.tsx"),
  "acf/content-feature-carousel": dynamic(
    () => import("../../../blocks/content/feature-carousel.tsx"),
    "blocks/content/feature-carousel.tsx",
  ),
  "acf/content-fill-the-gaps": dynamic(
    () => import("../../../blocks/content/fill-the-gaps.tsx"),
    "blocks/content/fill-the-gaps.tsx",
  ),
  "acf/content-form-embed": dynamic(
    () => import("../../../blocks/content/form-embed.tsx"),
    "blocks/content/form-embed.tsx",
  ),
  "acf/content-here-to-help": dynamic(
    () => import("../../../blocks/content/here-to-help.tsx"),
    "blocks/content/here-to-help.tsx",
  ),
  "acf/content-hero-cta": dynamic(() => import("../../../blocks/content/hero-cta.tsx"), "blocks/content/hero-cta.tsx"),
  "acf/content-hero-image": dynamic(
    () => import("../../../blocks/content/hero-image.tsx"),
    "blocks/content/hero-image.tsx",
  ),
  "acf/content-hero-image-video": dynamic(
    () => import("../../../blocks/content/hero-image-video.tsx"),
    "blocks/content/hero-image-video.tsx",
  ),
  "acf/content-hero-tile": dynamic(
    () => import("../../../blocks/content/hero-tile.tsx"),
    "blocks/content/hero-tile.tsx",
  ),
  "acf/content-image-text-tiles": dynamic(
    () => import("../../../blocks/content/image-text-tiles.tsx"),
    "blocks/content/image-text-tiles.tsx",
  ),
  "acf/content-marquee": dynamic(() => import("../../../blocks/content/marquee.tsx"), "blocks/content/marquee.tsx"),
  "acf/content-page-intro": dynamic(
    () => import("../../../blocks/content/page-intro.tsx"),
    "blocks/content/page-intro.tsx",
  ),
  "acf/content-pricing": dynamic(() => import("../../../blocks/content/pricing.tsx"), "blocks/content/pricing.tsx"),
  "acf/content-section-heading": dynamic(
    () => import("../../../blocks/content/section-heading.tsx"),
    "blocks/content/section-heading.tsx",
  ),
  "acf/content-small-cta": dynamic(
    () => import("../../../blocks/content/small-cta.tsx"),
    "blocks/content/small-cta.tsx",
  ),
  "acf/content-spotlight": dynamic(
    () => import("../../../blocks/content/spotlight.tsx"),
    "blocks/content/spotlight.tsx",
  ),
  "acf/content-staff": dynamic(() => import("../../../blocks/content/staff.tsx"), "blocks/content/staff.tsx"),
  "acf/content-subscribe": dynamic(
    () => import("../../../blocks/content/subscribe.tsx"),
    "blocks/content/subscribe.tsx",
  ),
  "acf/content-testimonials": dynamic(
    () => import("../../../blocks/content/testimonials.tsx"),
    "blocks/content/testimonials.tsx",
  ),
  "acf/content-trusted-by": dynamic(
    () => import("../../../blocks/content/trusted-by.tsx"),
    "blocks/content/trusted-by.tsx",
  ),
  "acf/content-value-points": dynamic(
    () => import("../../../blocks/content/value-points.tsx"),
    "blocks/content/value-points.tsx",
  ),
  "acf/content-vertical-space": dynamic(
    () => import("../../../blocks/content/vertical-space.tsx"),
    "blocks/content/vertical-space.tsx",
  ),
  "acf/events-event-details": dynamic(
    () => import("../../../blocks/events/event-details.tsx"),
    "blocks/events/event-details.tsx",
  ),
  "acf/events-event-filter-results": dynamic(
    () => import("../../../blocks/events/event-filter-results.tsx"),
    "blocks/events/event-filter-results.tsx",
  ),
  "acf/events-events": dynamic(() => import("../../../blocks/events/events.tsx"), "blocks/events/events.tsx"),
  "acf/faq-faq-details": dynamic(() => import("../../../blocks/faq/faq-details.tsx"), "blocks/faq/faq-details.tsx"),
  "acf/features-feature-hero": dynamic(
    () => import("../../../blocks/features/feature-hero.tsx"),
    "blocks/features/feature-hero.tsx",
  ),
  "acf/home-home-intro": dynamic(() => import("../../../blocks/home/home-intro.tsx"), "blocks/home/home-intro.tsx"),
  ...core,
};

const info = {
  "acf/accountants-carbon-accountant-certification": {
    "slug": "accountants/carbon-accountant-certification",
    "tags": ["accountants"],
    "flags": {},
  },
  "acf/accountants-carbon-accountant-contact": {
    "slug": "accountants/carbon-accountant-contact",
    "tags": ["accountants"],
    "flags": {},
  },
  "acf/accountants-carbon-accountant-filter-results": {
    "slug": "accountants/carbon-accountant-filter-results",
    "tags": ["root"],
    "flags": {},
  },
  "acf/accountants-carbon-accountant-header": {
    "slug": "accountants/carbon-accountant-header",
    "tags": ["accountants"],
    "flags": {},
  },
  "acf/accountants-carbon-accountant-intro": {
    "slug": "accountants/carbon-accountant-intro",
    "tags": ["accountants"],
    "flags": {},
  },
  "acf/accountants-carbon-accountant-services": {
    "slug": "accountants/carbon-accountant-services",
    "tags": ["accountants"],
    "flags": {},
  },
  "acf/blog-blog-archive-hero": { "slug": "blog/blog-archive-hero", "tags": ["root"], "flags": {} },
  "acf/blog-blog-featured-posts": { "slug": "blog/blog-featured-posts", "tags": ["root"], "flags": {} },
  "acf/blog-blog-post-content": { "slug": "blog/blog-post-content", "tags": ["root", "inline"], "flags": {} },
  "acf/blog-blog-post-details": { "slug": "blog/blog-post-details", "tags": ["root"], "flags": {} },
  "acf/content-advisors-ad": { "slug": "content/advisors-ad", "tags": ["root"], "flags": {} },
  "acf/content-anchor": { "slug": "content/anchor", "tags": ["root"], "flags": {} },
  "acf/content-anchor-button-group": { "slug": "content/anchor-button-group", "tags": ["root"], "flags": {} },
  "acf/content-animated-title": { "slug": "content/animated-title", "tags": ["root"], "flags": {} },
  "acf/content-averages-actuals": { "slug": "content/averages-actuals", "tags": ["root"], "flags": {} },
  "acf/content-banner": { "slug": "content/banner", "tags": ["root"], "flags": {} },
  "acf/content-card-carousel": { "slug": "content/card-carousel", "tags": ["root"], "flags": {} },
  "acf/content-card-image": { "slug": "content/card-image", "tags": ["root"], "flags": {} },
  "acf/content-careers": { "slug": "content/careers", "tags": ["root"], "flags": {} },
  "acf/content-chapters": { "slug": "content/chapters", "tags": ["root"], "flags": {} },
  "acf/content-curated-gallery": { "slug": "content/curated-gallery", "tags": ["root"], "flags": {} },
  "acf/content-faq": { "slug": "content/faq", "tags": ["root"], "flags": {} },
  "acf/content-feature-carousel": { "slug": "content/feature-carousel", "tags": ["root"], "flags": {} },
  "acf/content-fill-the-gaps": { "slug": "content/fill-the-gaps", "tags": ["root"], "flags": {} },
  "acf/content-form-embed": { "slug": "content/form-embed", "tags": ["root"], "flags": {} },
  "acf/content-here-to-help": { "slug": "content/here-to-help", "tags": ["root"], "flags": {} },
  "acf/content-hero-cta": { "slug": "content/hero-cta", "tags": ["root"], "flags": {} },
  "acf/content-hero-image": { "slug": "content/hero-image", "tags": ["root"], "flags": {} },
  "acf/content-hero-image-video": { "slug": "content/hero-image-video", "tags": ["root"], "flags": {} },
  "acf/content-hero-tile": { "slug": "content/hero-tile", "tags": ["root"], "flags": {} },
  "acf/content-image-text-tiles": { "slug": "content/image-text-tiles", "tags": ["root"], "flags": {} },
  "acf/content-marquee": { "slug": "content/marquee", "tags": ["root"], "flags": {} },
  "acf/content-page-intro": { "slug": "content/page-intro", "tags": ["root"], "flags": {} },
  "acf/content-pricing": { "slug": "content/pricing", "tags": ["root"], "flags": {} },
  "acf/content-section-heading": { "slug": "content/section-heading", "tags": ["root"], "flags": {} },
  "acf/content-small-cta": { "slug": "content/small-cta", "tags": ["root"], "flags": {} },
  "acf/content-spotlight": { "slug": "content/spotlight", "tags": ["root"], "flags": {} },
  "acf/content-staff": { "slug": "content/staff", "tags": ["root"], "flags": {} },
  "acf/content-subscribe": { "slug": "content/subscribe", "tags": ["root"], "flags": {} },
  "acf/content-testimonials": { "slug": "content/testimonials", "tags": ["root"], "flags": {} },
  "acf/content-trusted-by": { "slug": "content/trusted-by", "tags": ["root"], "flags": {} },
  "acf/content-value-points": { "slug": "content/value-points", "tags": ["root"], "flags": {} },
  "acf/content-vertical-space": { "slug": "content/vertical-space", "tags": ["root"], "flags": {} },
  "acf/events-event-details": { "slug": "events/event-details", "tags": ["event"], "flags": {} },
  "acf/events-event-filter-results": { "slug": "events/event-filter-results", "tags": ["root"], "flags": {} },
  "acf/events-events": { "slug": "events/events", "tags": ["root"], "flags": {} },
  "acf/faq-faq-details": { "slug": "faq/faq-details", "tags": ["root"], "flags": {} },
  "acf/features-feature-hero": { "slug": "features/feature-hero", "tags": ["root"], "flags": {} },
  "acf/home-home-intro": { "slug": "home/home-intro", "tags": ["root"], "flags": {} },
};

blockManifestReader.set(manifest, info);

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}

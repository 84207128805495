import { useEffect, useState } from "react"

export const SVGLogo = ({ logoUrl, className }: { logoUrl: string; className?: string }) => {
  const fetchSvgContent = async (url: string, setContent: (content: string) => void) => {
    try {
      const response = await fetch(url)
      if (response.ok) {
        const text = await response.text()
        setContent(text)
      } else {
        console.error(`Failed to fetch SVG: ${response.statusText}`)
      }
    } catch (error) {
      console.error("Error fetching SVG:", error)
    }
  }

  const [svgContent, setSvgContent] = useState<string | null>(null)

  useEffect(() => {
    if (logoUrl.endsWith(".svg")) {
      fetchSvgContent(logoUrl, setSvgContent)
    }
  }, [logoUrl])

  return (
    <>
      {svgContent ? (
        <i className={className} dangerouslySetInnerHTML={{ __html: svgContent }} />
      ) : (
        <img src={logoUrl} className="w-auto max-h-[75px] px-9" />
      )}
    </>
  )
}

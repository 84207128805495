import { create } from "zustand"

type FeatureThemeStore = {
  theme: "light" | "dark" | "bold"
  setTheme(data: "light" | "dark" | "bold"): void
}

export const useFeatureTheme = create<FeatureThemeStore>((set, get) => ({
  theme: "light",
  setTheme: (data) => set({ theme: data }),
}))

export const Logo = () => {
  return (
    <svg width="113" height="25" viewBox="0 0 113 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.5413 17.3818C38.851 17.0964 38.2968 16.685 37.8811 16.145C37.4655 15.605 37.206 14.9622 37.0977 14.2166H40.2568C40.3172 14.6511 40.4936 14.9854 40.7858 15.2194C41.078 15.4533 41.471 15.5716 41.9623 15.5716C42.3729 15.5716 42.6954 15.4868 42.9297 15.3171C43.164 15.1474 43.2773 14.9159 43.2773 14.6254C43.2773 14.4223 43.2295 14.25 43.1337 14.1111C43.038 13.9723 42.8717 13.8514 42.6349 13.746C42.3981 13.6406 42.0681 13.5506 41.6423 13.476C40.612 13.2986 39.7932 13.0698 39.1861 12.7844C38.579 12.4989 38.1356 12.1338 37.8559 11.6864C37.5763 11.239 37.4378 10.6733 37.4378 9.9868C37.4378 9.30027 37.6166 8.73716 37.9769 8.23319C38.3346 7.72922 38.841 7.33839 39.4909 7.06326C40.1409 6.78813 40.8967 6.65186 41.7532 6.65186C43.0808 6.65186 44.1238 6.94755 44.877 7.54152C45.6303 8.13548 46.0787 9.00457 46.2173 10.1514H43.1488C43.0959 9.71682 42.9574 9.39798 42.7357 9.19485C42.514 8.99172 42.1966 8.88887 41.7834 8.88887C41.4055 8.88887 41.1032 8.96858 40.8815 9.128C40.6598 9.28742 40.5465 9.50855 40.5465 9.79396C40.5465 10.0099 40.5994 10.1899 40.7052 10.3339C40.811 10.4779 40.9949 10.5962 41.2544 10.6939C41.5139 10.7916 41.8792 10.879 42.3502 10.9536C43.3277 11.1105 44.1087 11.329 44.6982 11.6093C45.2877 11.8895 45.7159 12.2572 45.988 12.7072C46.2601 13.1572 46.3961 13.7229 46.3961 14.4017C46.3961 15.0805 46.2097 15.6565 45.8369 16.1707C45.464 16.685 44.9425 17.0887 44.2674 17.3767C43.5922 17.6646 42.8163 17.8086 41.9396 17.8086C41.0629 17.8086 40.2291 17.6672 39.5388 17.3818H39.5413Z"
        fill="currentColor"
      />
      <path
        d="M48.8257 16.7005C48.1607 15.9625 47.8281 14.9211 47.8281 13.5789V6.99902H51.0175V12.8975C51.0175 13.5609 51.156 14.0649 51.4306 14.4094C51.7052 14.7514 52.1209 14.9237 52.6701 14.9237C53.3553 14.9237 53.8667 14.6871 54.2043 14.2115C54.5444 13.7512 54.7132 13.093 54.7132 12.2393V7.00159H57.9025V17.4796H55.5697C55.5622 17.3433 55.547 17.1916 55.5244 17.0219C55.5017 16.8522 55.4715 16.7107 55.4387 16.595C55.3732 16.3302 55.2498 16.1991 55.0709 16.1991C54.9978 16.1991 54.9323 16.2171 54.8769 16.2556C54.8215 16.2916 54.7484 16.3636 54.6628 16.4639C54.2773 16.919 53.8415 17.2584 53.3528 17.4821C52.8641 17.7058 52.2947 17.819 51.6448 17.819C50.428 17.819 49.4883 17.4487 48.8232 16.7107L48.8257 16.7005Z"
        fill="currentColor"
      />
      <path
        d="M60 17.4745V6.99654H62.3227C62.3504 7.30766 62.3958 7.60336 62.4613 7.88106C62.5343 8.17161 62.6578 8.31817 62.8291 8.31817C62.9022 8.31817 62.9651 8.30017 63.018 8.26675C63.0709 8.23332 63.144 8.16133 63.2372 8.05333C63.6478 7.57765 64.0937 7.22538 64.5724 6.99654C65.051 6.76512 65.575 6.65199 66.1469 6.65199C66.817 6.65199 67.3763 6.77284 67.8222 7.01711C68.2681 7.26138 68.6435 7.63421 68.9483 8.13561C69.034 8.27703 69.107 8.37217 69.1675 8.42102C69.2279 8.46731 69.2959 8.49302 69.3766 8.49302C69.4496 8.49302 69.5202 8.46731 69.5857 8.41588C69.6512 8.36446 69.7393 8.27189 69.8451 8.13561C70.2709 7.60079 70.7118 7.21767 71.1703 6.99139C71.6288 6.76512 72.1779 6.64941 72.8153 6.64941C73.5383 6.64941 74.1631 6.81655 74.6896 7.14824C75.2136 7.47994 75.6167 7.96077 75.8963 8.5933C76.176 9.22326 76.3145 9.97665 76.3145 10.8509V17.4719H73.1252V11.3883C73.1252 10.7995 73.0043 10.3392 72.7624 10.0152C72.5206 9.69124 72.1603 9.52667 71.6817 9.52667C71.0569 9.52667 70.5656 9.79409 70.2079 10.3289C69.9081 10.7892 69.7595 11.4448 69.7595 12.2908V17.4694H66.5701V11.3857C66.5701 10.7969 66.4492 10.3366 66.2074 10.0126C65.9655 9.68609 65.6053 9.5241 65.1266 9.5241C64.5019 9.5241 64.0131 9.79152 63.663 10.3263C63.3506 10.7866 63.1944 11.4423 63.1944 12.2882V17.4668H60.005L60 17.4745Z"
        fill="currentColor"
      />
      <path
        d="M80.1906 17.0886C79.4223 16.6078 78.8227 15.9418 78.3869 15.0959C77.951 14.2499 77.7344 13.2986 77.7344 12.2469C77.7344 11.1953 77.9611 10.2542 78.412 9.40307C78.863 8.55198 79.4827 7.88602 80.2662 7.40519C81.0497 6.92436 81.9213 6.68266 82.8761 6.68266C83.448 6.68266 83.9619 6.76751 84.4154 6.93721C84.8688 7.10692 85.2543 7.33319 85.5667 7.6186C85.6397 7.68031 85.6951 7.72145 85.7354 7.73945C85.7758 7.76002 85.8186 7.77031 85.8639 7.77031C85.9899 7.77031 86.0529 7.66746 86.0529 7.46433V2.7666H89.2422V17.4743H86.8993C86.8867 17.1632 86.8439 16.8675 86.7708 16.5898C86.7305 16.4535 86.6852 16.3584 86.6323 16.2992C86.5794 16.2427 86.5063 16.2118 86.4131 16.2118C86.3527 16.2118 86.2947 16.2298 86.2342 16.2684C86.1738 16.307 86.1007 16.3687 86.0151 16.4561C85.1711 17.3586 84.0979 17.8086 82.7955 17.8086C81.8256 17.8086 80.9565 17.5669 80.1906 17.086V17.0886ZM85.2795 14.3939C85.544 14.1497 85.7556 13.8437 85.9067 13.4785C86.0579 13.1134 86.136 12.702 86.136 12.2469C86.136 11.7918 86.0604 11.3932 85.9067 11.0255C85.7531 10.6604 85.544 10.3544 85.2795 10.1102C84.8134 9.68334 84.234 9.46992 83.5362 9.46992C83.0323 9.46992 82.5889 9.58563 82.206 9.81447C81.8231 10.0433 81.5283 10.3699 81.3192 10.7916C81.1101 11.2133 81.0043 11.6967 81.0043 12.2469C81.0043 12.7972 81.1101 13.2831 81.3192 13.7074C81.5283 14.1317 81.8231 14.4582 82.206 14.6896C82.5889 14.921 83.0323 15.0342 83.5362 15.0342C84.234 15.0342 84.8159 14.8208 85.2795 14.3939Z"
        fill="currentColor"
      />
      <path
        d="M99.3937 7.7701C100.175 8.51577 100.565 9.59828 100.565 11.0151V17.4741H98.2122C98.1921 17.0678 98.1467 16.7567 98.0736 16.5382C98.0283 16.3479 97.9401 16.2527 97.8142 16.2527C97.7537 16.2527 97.6983 16.2707 97.6504 16.3042C97.6 16.3376 97.5421 16.3993 97.4766 16.4867C97.1113 16.9419 96.6981 17.271 96.2396 17.4792C95.7811 17.6849 95.2168 17.7904 94.5442 17.7904C93.8187 17.7904 93.1712 17.6592 92.5968 17.3944C92.0225 17.1296 91.5715 16.7413 91.2465 16.2296C90.9216 15.7179 90.7578 15.1137 90.7578 14.4143C90.7578 13.4243 91.1029 12.6581 91.7932 12.1156C92.4835 11.573 93.5063 11.2619 94.8616 11.1796C96.023 11.105 96.9072 10.9893 97.5119 10.8351V10.7142C97.5119 10.1922 97.3733 9.79113 97.0987 9.51343C96.8241 9.23573 96.4336 9.09688 95.9273 9.09688C95.4688 9.09688 95.0959 9.20745 94.8112 9.42858C94.5266 9.64971 94.3402 9.94797 94.2545 10.3285H91.0954C91.2289 9.61114 91.5035 8.97346 91.9167 8.42064C92.3298 7.86781 92.884 7.43584 93.5768 7.12471C94.2671 6.81359 95.0758 6.65674 95.9978 6.65674C97.4791 6.65674 98.6102 7.02957 99.3912 7.77524L99.3937 7.7701ZM97.5144 12.9872V12.7018C97.1289 12.8432 96.4437 12.9641 95.4612 13.0566C94.9171 13.1184 94.5266 13.2444 94.2948 13.4321C94.063 13.6223 93.9472 13.8923 93.9472 14.2472C93.9472 14.6483 94.0807 14.9568 94.3502 15.1728C94.6198 15.3888 94.9901 15.4994 95.4612 15.4994C95.8542 15.4994 96.1968 15.4222 96.4916 15.2654C96.7863 15.1085 97.0206 14.8694 97.1944 14.5428C97.4061 14.1572 97.5144 13.6378 97.5144 12.9872Z"
        fill="currentColor"
      />
      <path
        d="M107.35 13.8746L109.693 6.99902H113L107.121 21.8507H103.813L105.556 17.4667L101.43 6.99902H104.957L107.347 13.8746H107.35Z"
        fill="currentColor"
      />
      <path
        d="M6.15951 10.478H0.68523C0.306788 10.478 0 10.7912 0 11.1774V13.2704C0 13.6567 0.306788 13.9698 0.68523 13.9698H6.15951C6.53795 13.9698 6.84474 13.6567 6.84474 13.2704V11.1774C6.84474 10.7912 6.53795 10.478 6.15951 10.478Z"
        fill="currentColor"
      />
      <path
        d="M9.57963 13.9722H7.52898C7.15054 13.9722 6.84375 14.2853 6.84375 14.6716V16.7646C6.84375 17.1508 7.15054 17.464 7.52898 17.464H9.57963C9.95807 17.464 10.2649 17.1508 10.2649 16.7646V14.6716C10.2649 14.2853 9.95807 13.9722 9.57963 13.9722Z"
        fill="currentColor"
      />
      <path
        d="M6.15776 17.4639H4.1071C3.72866 17.4639 3.42188 17.777 3.42188 18.1633V20.2563C3.42188 20.6425 3.72866 20.9557 4.1071 20.9557H6.15776C6.5362 20.9557 6.84299 20.6425 6.84299 20.2563V18.1633C6.84299 17.777 6.5362 17.4639 6.15776 17.4639Z"
        fill="currentColor"
      />
      <path
        d="M16.4273 13.9722H14.3766C13.9982 13.9722 13.6914 14.2853 13.6914 14.6716V16.7646C13.6914 17.1508 13.9982 17.464 14.3766 17.464H16.4273C16.8057 17.464 17.1125 17.1508 17.1125 16.7646V14.6716C17.1125 14.2853 16.8057 13.9722 16.4273 13.9722Z"
        fill="currentColor"
      />
      <path
        d="M19.8453 17.4639H17.7946C17.4162 17.4639 17.1094 17.777 17.1094 18.1633V20.2563C17.1094 20.6425 17.4162 20.9557 17.7946 20.9557H19.8453C20.2237 20.9557 20.5305 20.6425 20.5305 20.2563V18.1633C20.5305 17.777 20.2237 17.4639 19.8453 17.4639Z"
        fill="currentColor"
      />
      <path
        d="M9.57963 6.98633H7.52898C7.15054 6.98633 6.84375 7.29945 6.84375 7.68572V9.77874C6.84375 10.165 7.15054 10.4781 7.52898 10.4781H9.57963C9.95807 10.4781 10.2649 10.165 10.2649 9.77874V7.68572C10.2649 7.29945 9.95807 6.98633 9.57963 6.98633Z"
        fill="currentColor"
      />
      <path
        d="M6.15776 3.4917H4.1071C3.72866 3.4917 3.42188 3.80483 3.42188 4.19109V6.28411C3.42188 6.67037 3.72866 6.98349 4.1071 6.98349H6.15776C6.5362 6.98349 6.84299 6.67037 6.84299 6.28411V4.19109C6.84299 3.80483 6.5362 3.4917 6.15776 3.4917Z"
        fill="currentColor"
      />
      <path
        d="M16.4273 6.98633H14.3766C13.9982 6.98633 13.6914 7.29945 13.6914 7.68572V9.77874C13.6914 10.165 13.9982 10.4781 14.3766 10.4781H16.4273C16.8057 10.4781 17.1125 10.165 17.1125 9.77874V7.68572C17.1125 7.29945 16.8057 6.98633 16.4273 6.98633Z"
        fill="currentColor"
      />
      <path
        d="M19.8453 3.4917H17.7946C17.4162 3.4917 17.1094 3.80483 17.1094 4.19109V6.28411C17.1094 6.67037 17.4162 6.98349 17.7946 6.98349H19.8453C20.2237 6.98349 20.5305 6.67037 20.5305 6.28411V4.19109C20.5305 3.80483 20.2237 3.4917 19.8453 3.4917Z"
        fill="currentColor"
      />
      <path
        d="M23.2689 10.478H17.7946C17.4162 10.478 17.1094 10.7912 17.1094 11.1774V13.2704C17.1094 13.6567 17.4162 13.9698 17.7946 13.9698H23.2689C23.6473 13.9698 23.9541 13.6567 23.9541 13.2704V11.1774C23.9541 10.7912 23.6473 10.478 23.2689 10.478Z"
        fill="currentColor"
      />
      <path
        d="M13.6914 6.28677V0.699388C13.6914 0.313127 13.3846 0 13.0062 0L10.9555 0C10.5771 0 10.2703 0.313127 10.2703 0.699388V6.28677C10.2703 6.67304 10.5771 6.98616 10.9555 6.98616H13.0062C13.3846 6.98616 13.6914 6.67304 13.6914 6.28677Z"
        fill="currentColor"
      />
      <path
        d="M13.6914 23.7506V18.1633C13.6914 17.777 13.3846 17.4639 13.0062 17.4639H10.9555C10.5771 17.4639 10.2703 17.777 10.2703 18.1633V23.7506C10.2703 24.1369 10.5771 24.45 10.9555 24.45H13.0062C13.3846 24.45 13.6914 24.1369 13.6914 23.7506Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const LogoMark = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.79905 7.31226H1.32517C1.08542 7.31226 0.890625 7.51198 0.890625 7.75663V9.08476C0.890625 9.32942 1.08542 9.52914 1.32517 9.52914H4.79905C5.0388 9.52914 5.2336 9.32942 5.2336 9.08476V7.75663C5.2336 7.51198 5.0388 7.31226 4.79905 7.31226Z"
        fill="currentColor"
      />
      <path
        d="M6.96616 9.52661H5.66502C5.42527 9.52661 5.23047 9.72633 5.23047 9.97099V11.2991C5.23047 11.5438 5.42527 11.7435 5.66502 11.7435H6.96616C7.20591 11.7435 7.40071 11.5438 7.40071 11.2991V9.97099C7.40071 9.72633 7.20591 9.52661 6.96616 9.52661Z"
        fill="currentColor"
      />
      <path
        d="M4.79819 11.741H3.49705C3.2573 11.741 3.0625 11.9407 3.0625 12.1853V13.5135C3.0625 13.7581 3.2573 13.9578 3.49705 13.9578H4.79819C5.03794 13.9578 5.23274 13.7581 5.23274 13.5135V12.1853C5.23274 11.9407 5.03794 11.741 4.79819 11.741Z"
        fill="currentColor"
      />
      <path
        d="M11.3138 9.52661H10.0127C9.77292 9.52661 9.57812 9.72633 9.57812 9.97099V11.2991C9.57812 11.5438 9.77292 11.7435 10.0127 11.7435H11.3138C11.5536 11.7435 11.7484 11.5438 11.7484 11.2991V9.97099C11.7484 9.72633 11.5536 9.52661 11.3138 9.52661Z"
        fill="currentColor"
      />
      <path
        d="M13.4818 11.741H12.1806C11.9409 11.741 11.7461 11.9407 11.7461 12.1853V13.5135C11.7461 13.7581 11.9409 13.9578 12.1806 13.9578H13.4818C13.7215 13.9578 13.9163 13.7581 13.9163 13.5135V12.1853C13.9163 11.9407 13.7215 11.741 13.4818 11.741Z"
        fill="currentColor"
      />
      <path
        d="M6.96616 5.09521H5.66502C5.42527 5.09521 5.23047 5.29493 5.23047 5.53959V6.86772C5.23047 7.11238 5.42527 7.31209 5.66502 7.31209H6.96616C7.20591 7.31209 7.40071 7.11238 7.40071 6.86772V5.53959C7.40071 5.29493 7.20591 5.09521 6.96616 5.09521Z"
        fill="currentColor"
      />
      <path
        d="M4.79819 2.88086H3.49705C3.2573 2.88086 3.0625 3.08058 3.0625 3.32523V4.65336C3.0625 4.89802 3.2573 5.09774 3.49705 5.09774H4.79819C5.03794 5.09774 5.23274 4.89802 5.23274 4.65336V3.32523C5.23274 3.08058 5.03794 2.88086 4.79819 2.88086Z"
        fill="currentColor"
      />
      <path
        d="M11.3138 5.09521H10.0127C9.77292 5.09521 9.57812 5.29493 9.57812 5.53959V6.86772C9.57812 7.11238 9.77292 7.31209 10.0127 7.31209H11.3138C11.5536 7.31209 11.7484 7.11238 11.7484 6.86772V5.53959C11.7484 5.29493 11.5536 5.09521 11.3138 5.09521Z"
        fill="currentColor"
      />
      <path
        d="M13.4818 2.88086H12.1806C11.9409 2.88086 11.7461 3.08058 11.7461 3.32523V4.65336C11.7461 4.89802 11.9409 5.09774 12.1806 5.09774H13.4818C13.7215 5.09774 13.9163 4.89802 13.9163 4.65336V3.32523C13.9163 3.08058 13.7215 2.88086 13.4818 2.88086Z"
        fill="currentColor"
      />
      <path
        d="M15.6545 7.31226H12.1806C11.9409 7.31226 11.7461 7.51198 11.7461 7.75663V9.08476C11.7461 9.32942 11.9409 9.52914 12.1806 9.52914H15.6545C15.8943 9.52914 16.0891 9.32942 16.0891 9.08476V7.75663C16.0891 7.51198 15.8943 7.31226 15.6545 7.31226Z"
        fill="currentColor"
      />
      <path
        d="M9.57649 4.65345V1.10844C9.57649 0.863782 9.38169 0.664062 9.14194 0.664062H7.8408C7.60105 0.664062 7.40625 0.863782 7.40625 1.10844V4.65345C7.40625 4.8981 7.60105 5.09782 7.8408 5.09782H9.14194C9.38169 5.09782 9.57649 4.8981 9.57649 4.65345Z"
        fill="currentColor"
      />
      <path
        d="M9.57649 15.7304V12.1853C9.57649 11.9407 9.38169 11.741 9.14194 11.741H7.8408C7.60105 11.741 7.40625 11.9407 7.40625 12.1853V15.7304C7.40625 15.975 7.60105 16.1747 7.8408 16.1747H9.14194C9.38169 16.1747 9.57649 15.975 9.57649 15.7304Z"
        fill="currentColor"
      />
    </svg>
  )
}

import { Button } from "@components/atoms/Button"
import { Logo } from "@components/atoms/Logo"
import { Menu } from "@components/atoms/Menu"
import { useNav } from "@hooks/useNav"
import { Link } from "eddev/routing"
import { useEffect, useRef, useState } from "react"
import { Footer } from "./Footer"
import { useAppData } from "eddev/hooks"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { Icon } from "@components/atoms/Icons"
import { motion } from "motion/react"
import { useBannerVisibility } from "@utils/bannerVisibility"

type NavPosT = {
  xPos: number
  width: number
}

export function Header({ scheme }: { scheme: "light" | "dark" | "bold" }) {
  const navContainerRef = useRef<HTMLDivElement>(null)
  const navHoverRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const getNavPositions = () => {
    const buttons = navContainerRef.current!.querySelectorAll("div + ul > li > a")
    return Object.values(buttons).map((button: any) => ({
      xPos: button.offsetLeft,
      width: button.clientWidth,
    })) as NavPosT[]
  }

  const [navPositions, setNavPositions] = useState<NavPosT[] | null>(null)

  const navStatus = useNav()

  const fullNavHover = () => {
    if (navHoverRef.current && navContainerRef.current && dropdownRef.current) {
      setNavPositions(getNavPositions())
      const buttons = navContainerRef.current.querySelectorAll("div + ul > li > a")
      const mouseOverHandlers: ((event: Event) => void)[] = []

      const gridClasses = "!grid !grid-cols-2 !gap-7 animate-up"

      const decodeHTML = (html: string): string => {
        const txt = document.createElement("textarea")
        txt.innerHTML = html
        return txt.value
      }

      Object.values(buttons).forEach((button, i) => {
        const handleMouseOver = (event: Event) => {
          if (navPositions) {
            navHoverRef.current!.style.width = navPositions![i].width + "px"
            navHoverRef.current!.style.left = navPositions![i].xPos + "px"
            const left = navPositions![i].xPos - 48 + 16

            const gridLayout = button.nextElementSibling?.innerHTML.includes(
              `<p class="w-full text-left type-body-m text-textAlt">`,
            )
              ? true
              : false

            const haslevel2 = button.nextElementSibling?.innerHTML.includes(`data-menu-depth="2"`) ? true : false

            if (dropdownRef.current && button?.nextElementSibling instanceof HTMLElement) {
              // Create a new <ul> element
              const ul = document.createElement("ul")
              ul.style.left = `${left}px`
              ul.style.width = "auto"
              ul.className = haslevel2 ? "level2 animate-up" : gridLayout ? gridClasses : "animate-up"

              // Safely parse and set the HTML content
              const rawHTML = decodeHTML(button.nextElementSibling.innerHTML)
              ul.innerHTML = rawHTML

              // Append or replace the existing content
              dropdownRef.current.innerHTML = "" // Clear existing content if needed
              dropdownRef.current.appendChild(ul)
            } else {
              if (dropdownRef.current) dropdownRef.current.innerHTML = ""
            }
          }
        }

        mouseOverHandlers[i] = handleMouseOver
        button.addEventListener("mouseover", handleMouseOver)
      })

      const handleLeave = (event: Event) => {
        dropdownRef.current!.innerHTML = ""
      }

      dropdownRef.current.addEventListener("mouseleave", handleLeave)
      navContainerRef.current.addEventListener("mouseleave", handleLeave)

      return () => {
        Object.values(buttons).forEach((button, i) => {
          button.removeEventListener("mouseover", mouseOverHandlers[i])
        })
        dropdownRef.current?.removeEventListener("mouseleave", handleLeave)
      }
    }
  }

  useEffect(() => {
    fullNavHover()
  }, [navHoverRef.current, navContainerRef.current, dropdownRef.current])

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    const checkNavPositions = () => {
      const positions = getNavPositions()
      if (positions.length > 0) {
        setNavPositions(positions)
        clearInterval(intervalId)

        if (navHoverRef.current) {
          navHoverRef.current!.style.width = positions[0].width + "px"
          navHoverRef.current!.style.left = positions[0].xPos + "px"
        }
      }
    }

    intervalId = setInterval(checkNavPositions, 100)

    return () => clearInterval(intervalId)
  }, [])

  const headerBanner = useAppData().miscSettings?.miscSettings?.topBanner
  const [show, setShow] = useBannerVisibility("top-banner", true)

  return (
    <>
      <div
        className={`subtheme-${scheme} sticky top-0 w-full max-w-full flex justify-between items-center px-7 md:px-8 border-t-[1px] border-b-[1px] ${navStatus.open ? `mt-0 md:mt-0` : `mt-7`} transition-all in-out-sumday duration-300 py-5 border-ruler bg-bg z-40 ${headerBanner?.status && show ? `mb-[64px]` : ``}`}
      >
        <div className="grid-auto items-center w-full lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 w-full flex justify-between items-center">
            <div className="text-accent">
              <Link href="/">
                <i className="flex w-[135px] md:w-[115px]">
                  <Logo />
                </i>
              </Link>
            </div>

            <nav className="main-nav group" ref={navContainerRef} onMouseOver={() => fullNavHover()}>
              <div
                ref={navHoverRef}
                className={`absolute top-0 left-0 ${navPositions ? `p-5` : ``} bg-ruler rounded-sm h-[40px] z-10 transition-all in-out-sumday duration-300 opacity-0 group-hover:opacity-100`}
              />

              <Menu location="Main" />
              <div ref={dropdownRef} className={`nav-dropdown subtheme-${scheme}`} />
            </nav>

            <div className="hidden md:flex gap-4">
              <Button size="m" style="secondary" href="http://app.sumday.io/" target="_blank">
                Login
              </Button>
              <Button size="m" style="accent" href="https://app.sumday.io/signup/landing" target="_blank">
                Start for free
              </Button>
            </div>

            <div className="flex md:hidden">
              <Button size="l" style="accent" onClick={() => navStatus.setOpen(!navStatus.open)}>
                {navStatus.open ? `Close` : `Menu`}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 w-screen z-30 transition-all in-out-sumday duration-300 bg-bg overflow-hidden ${navStatus.open ? "min-h-screen" : "min-h-0 h-0"} md:hidden`}
      >
        <div className="flex flex-col gap-9 w-full pt-headerHeight max-h-screen overflow-auto">
          <div className="flex flex-col gap-9 w-full px-9">
            <nav className="mobile-nav">
              <Menu location="Main" mobile />
            </nav>

            <div className="flex flex-col gap-4">
              <Button size="xl" style="primary" href="https://app.sumday.io/signup/landing" target="_blank">
                Start for free
              </Button>
              <Button size="xl" style="secondary" rounded href="http://app.sumday.io/" target="_blank">
                Login
              </Button>
            </div>
          </div>
          <Footer scheme="light" />
        </div>
      </div>

      {headerBanner && show && <HeaderBanner setShow={setShow} scheme={scheme} />}
    </>
  )
}

const HeaderBanner = ({
  setShow,
  scheme,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean | null>>
  scheme: "light" | "dark" | "bold"
}) => {
  const banner = useAppData().miscSettings?.miscSettings?.topBanner

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.scrollY
      setIsVisible(scrollY < 100)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <>
      {banner?.status && (
        <motion.div
          className={`subtheme-${scheme} w-full bg-accent absolute top-headerHeight mt-7 left-0 px-7 md:px-0 py-4 border-b border-b-ruler z-20`}
          initial={{ opacity: 1 }}
          animate={{ opacity: isVisible ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="grid-auto items-center w-full gap-5 lg:max-w-[1280px] xl:max-w-[1440px]">
            <div className="col-span-11 flex gap-5 items-center justify-start">
              {banner?.logoLockup && (
                <ResponsiveImage
                  {...banner.logoLockup}
                  objectFit="contain"
                  className="hidden md:block max-h-[40px] w-auto [&>img]:!w-auto [&>img]:!h-auto [&>img]:!max-h-[40px]"
                />
              )}
              <span className="type-label-m md:type-label-l text-invert">{banner.text}</span>
              {banner?.button && (
                <Button
                  size="s"
                  style="secondary"
                  href={banner.button.url ?? undefined}
                  target={banner.button.target ? "_blank" : undefined}
                  className="whitespace-nowrap border border-invert shadow-none"
                >
                  {banner.button.title ?? "Click here"}
                </Button>
              )}
            </div>
            <div className="col-span-1 flex items-center justify-end">
              <button className="text-invert hover:text-invertAlt flex w-7 h-7" onClick={() => setShow(false)}>
                <Icon name="CROSS" />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  )
}

import { create } from "zustand"

type VideoEmbedStore = {
  video: string | null
  setVideo(data: string | null): void
}

export const useVideoEmbed = create<VideoEmbedStore>((set, get) => ({
  video: null,
  setVideo: (data) => set({ video: data }),
}))

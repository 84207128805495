import { useRef, useEffect, useState, useCallback } from "react"

export function useAdjustedHeight() {
  const refs = useRef<Set<HTMLDivElement>>(new Set())
  const baseHeight = useRef(80)

  const updateBaseHeight = () => {
    baseHeight.current = 80
  }

  const addRef = (ref: HTMLDivElement) => {
    if (ref) refs.current.add(ref)
  }

  const removeRef = (ref: HTMLDivElement) => {
    refs.current.delete(ref)
  }

  // Function to manually trigger height recalculation
  const updateHeights = useCallback(() => {
    const timeouts: NodeJS.Timeout[] = []

    refs.current.forEach((element) => {
      if (!element) return

      element.style.height = "auto" // Reset height to allow proper recalculation
      requestAnimationFrame(() => {
        const contentHeight = element.scrollHeight
        const newHeight = Math.ceil(contentHeight / baseHeight.current) * baseHeight.current
        element.style.height = `${newHeight}px`
      })

      const timeout = setTimeout(() => {
        const contentHeight = element.scrollHeight
        const newHeight = Math.ceil(contentHeight / baseHeight.current) * baseHeight.current
        element.style.height = `${newHeight}px`
      }, 100)

      timeouts.push(timeout)
    })

    return () => {
      timeouts.forEach(clearTimeout) // Clean up all timeouts
    }
  }, [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateHeights)

    // Observe all refs
    refs.current.forEach((ref) => {
      if (ref) resizeObserver.observe(ref)
    })

    // Update heights on window resize
    const onWindowResize = () => {
      updateBaseHeight()
      updateHeights()
    }

    window.addEventListener("resize", onWindowResize)

    // Initial height adjustment
    updateHeights()

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener("resize", onWindowResize)
    }
  }, [updateHeights])

  return { addRef, removeRef, updateHeights } // Expose updateHeights for external use
}

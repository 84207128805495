import { useEffect } from "react"

export const useViewportWidth = () => {
  useEffect(() => {
    const updateViewportWidth = () => {
      document.documentElement.style.setProperty("--viewport", `${document.documentElement.clientWidth}px`)
    }
    updateViewportWidth()
    window.addEventListener("resize", updateViewportWidth)
    return () => {
      window.removeEventListener("resize", updateViewportWidth)
    }
  }, [])
}

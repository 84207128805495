import { kebabCase } from "@utils/kebab-case"
import { ContentBlock } from "eddev/blocks"
import { useEffect, useRef } from "react"

const ParagraphBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content paragraph-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

const HeadingBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    const id = kebabCase(props.innerHTML.replace(/<\/?[^>]+(>|$)/g, "").trim())
    return (
      <div className="rich-text-content heading-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} id={id} />
    )
  } else {
    return null
  }
}

const ListBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content list-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

const ImageBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content image-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

const CodeBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content code-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

const QuoteBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content quote-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

const EmbedBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content embed-block" dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

const HTMLBlock = (props: ContentBlock) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!props.innerHTML) return

    // Create a temporary container to parse the HTML
    const tempContainer = document.createElement("div")
    tempContainer.innerHTML = props.innerHTML

    // Extract all script tags
    const scriptTags = Array.from(tempContainer.querySelectorAll("script"))

    // Remove script tags from the content (so it doesn’t execute immediately)
    scriptTags.forEach((script) => script.remove())

    // Inject cleaned HTML into the component
    if (containerRef.current) {
      containerRef.current.innerHTML = tempContainer.innerHTML
    }

    // Load scripts manually in useEffect
    scriptTags.forEach((script) => {
      if (script.src) {
        // If the script has a src attribute, load it dynamically
        const newScript = document.createElement("script")
        newScript.src = script.src
        newScript.async = true
        document.body.appendChild(newScript)
      } else {
        // If the script is inline, evaluate it
        try {
          eval(script.innerHTML) // Be careful with eval, ensure you trust the source
        } catch (error) {
          console.error("Error executing inline script:", error)
        }
      }
    })

    return () => {
      // Cleanup: Remove dynamically added scripts to avoid duplication
      scriptTags.forEach((script) => {
        if (script.src) {
          document.querySelectorAll(`script[src="${script.src}"]`).forEach((s) => s.remove())
        }
      })
    }
  }, [props.innerHTML])

  if (typeof props.innerHTML === "string") {
    return <div className="rich-text-content html-block" ref={containerRef} />
  } else {
    return null
  }
}

export default {
  "core/paragraph": ParagraphBlock,
  "core/heading": HeadingBlock,
  "core/list": ListBlock,
  "core/image": ImageBlock,
  "core/code": CodeBlock,
  "core/quote": QuoteBlock,
  "core/embed": EmbedBlock,
  "core/html": HTMLBlock,
}

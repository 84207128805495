import { GridLines } from "@components/atoms/GridLines"
import { Icon } from "@components/atoms/Icons"
import { Footer } from "@components/site/Footer"
import { Header } from "@components/site/Header"
import { useFeatureTheme } from "@hooks/useFeatureTheme"
import { useNav } from "@hooks/useNav"
import { useVideoEmbed } from "@hooks/useVideoEmbed"
import { useViewportWidth } from "@hooks/useViewportWidth"
import { useAppData } from "eddev/hooks"
import { ScrollRestoration, useRoute } from "eddev/routing"
import { defineView } from "eddev/views"
import { useEffect, useState } from "react"
import { motion } from "motion/react"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { Button } from "@components/atoms/Button"
import { useBannerVisibility } from "@utils/bannerVisibility"
import { SVGLogo } from "@components/atoms/SVGLogo"

type ThemeColorT = "light" | "dark" | "bold"

type ThemeT = {
  page: ThemeColorT
  header: ThemeColorT
  footer: ThemeColorT
}
export default defineView("_app", (props) => {
  const route = useRoute()
  const navStatus = useNav()
  const featureTheme = useFeatureTheme()

  useEffect(() => {
    navStatus.setOpen(false)
  }, [route])

  function useThemeClass(): ThemeT {
    switch (route.view) {
      case "front-page": {
        return {
          page: "light",
          header: "light",
          footer: "bold",
        }
      }

      case "templates/light": {
        return {
          page: "light",
          header: "light",
          footer: "light",
        }
      }

      case "templates/dark": {
        return {
          page: "dark",
          header: "dark",
          footer: "dark",
        }
      }

      case "templates/bold": {
        return {
          page: "bold",
          header: "bold",
          footer: "bold",
        }
      }

      case "single-feature": {
        return {
          page: featureTheme.theme,
          header: featureTheme.theme,
          footer: featureTheme.theme,
        }
      }

      case "archive-blog-post": {
        return {
          page: "bold",
          header: "bold",
          footer: "bold",
        }
      }

      case "taxonomy-blog-post-category": {
        return {
          page: "bold",
          header: "bold",
          footer: "bold",
        }
      }

      default: {
        return {
          page: "light",
          header: "light",
          footer: "bold",
        }
      }
    }
  }
  const themeClass = useThemeClass()
  useViewportWidth()

  const videoEmbed = useVideoEmbed()
  const banner = useAppData().miscSettings?.miscSettings?.floatingBanner

  return (
    <>
      <ScrollRestoration />
      <div className={`subtheme-${themeClass.page} flex flex-col relative bg-bg`}>
        <div className="flex flex-col justify-between min-h-screen relative z-10">
          <div>
            <Header scheme={themeClass.header} />
            <div className="w-full flex flex-col">{props.children}</div>
          </div>
          <Footer scheme={themeClass.footer} />
        </div>
        <GridLines />
      </div>
      {videoEmbed.video && <VideoEmbed video={videoEmbed.video} />}
      {banner?.status && <FloatingBanner scheme={themeClass.header} />}
    </>
  )
})

const VideoEmbed = ({ video }: { video: string }) => {
  const videoEmbed = useVideoEmbed()
  return (
    <div className="fixed z-50 bg-black bg-opacity-85 w-full h-full left-0 top-0 flex items-center justify-center ">
      <button className="absolute right-7 top-7 text-white hover:text-grey" onClick={() => videoEmbed.setVideo(null)}>
        <Icon name="CROSS" />
      </button>
      <div
        className="w-full md:w-[70vw] h-auto [&>iframe]:w-full [&>iframe]:h-[calc(100vw*(9/16))] md:[&>iframe]:h-[calc(70vw*(9/16))]"
        dangerouslySetInnerHTML={{ __html: video }}
      />
    </div>
  )
}

const FloatingBanner = ({ scheme }: { scheme: "light" | "dark" | "bold" }) => {
  const banner = useAppData().miscSettings?.miscSettings?.floatingBanner

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.scrollY
      setIsVisible(scrollY > 100)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const [show, setShow] = useBannerVisibility("floating-banner", true)
  if (!show) return <></>

  return (
    <>
      {banner?.status && show && (
        <motion.div
          className={`subtheme-${scheme} w-[calc(100%-(var(--spacing-7)*2))] md:w-auto max-w-[calc(100%-(var(--spacing-7)*2))] bg-accent fixed bottom-8 left-1/2 -translate-x-1/2 p-4 md:p-6 rounded-sm z-50 shadow-glow`}
          initial={{ opacity: 1 }}
          animate={{ opacity: isVisible ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex items-center justify-center gap-5 md:gap-8">
            <div className="flex gap-5 md:gap-8 items-center justify-start">
              {banner?.logoLockup && (
                <>
                  {banner.logoLockup.mediaItemUrl?.includes(".svg") ? (
                    <SVGLogo
                      className="hidden md:block max-h-[40px] w-auto [&>svg]:!w-auto [&>svg]:!h-auto [&>svg]:!max-h-[40px] text-invert"
                      logoUrl={banner.logoLockup.mediaItemUrl ?? ""}
                    />
                  ) : (
                    <ResponsiveImage
                      {...banner.logoLockup}
                      objectFit="contain"
                      className="hidden md:block max-h-[40px] w-auto [&>img]:!w-auto [&>img]:!h-auto [&>img]:!max-h-[40px]"
                    />
                  )}
                </>
              )}
              <span className="type-label-s md:type-label-l text-invert md:whitespace-nowrap">{banner.text}</span>
              {banner?.button && (
                <Button
                  size="s"
                  style="secondary"
                  href={banner.button.url ?? undefined}
                  target={banner.button.target ? "_blank" : undefined}
                  className="whitespace-nowrap border border-invert shadow-none"
                >
                  {banner.button.title ?? "Click here"}
                </Button>
              )}
            </div>
            <div className="flex items-center justify-end">
              <button className="text-invert hover:text-invertAlt flex w-7 h-7" onClick={() => setShow(false)}>
                <Icon name="CROSS" />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  )
}

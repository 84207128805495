import { Link } from "eddev/routing"
import React, { useMemo } from "react"
import { Icon } from "./Icons"

export function Button({
  children,
  href,
  target,
  onClick,
  size,
  style,
  rounded,
  disabled,
  iconLeft,
  iconRight,
  className,
  type,
}: {
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode
  href?: string
  target?: string
  onClick?: any
  size: "s" | "m" | "l" | "xl"
  style:
    | "primary"
    | "secondary"
    | "inverted"
    | "transparentPrimary"
    | "accent"
    | "invertedAccent"
    | "invertedHidden"
    | "accentAlt"
  rounded?: boolean
  disabled?: boolean
  iconLeft?: string
  iconRight?: string
  className?: string
  type?: "submit" | "reset" | "button" | undefined
}) {
  const classes = useMemo(() => {
    const commonClasses = `${className ? className : ``} appearance-none flex items-center justify-center gap-2 w-auto text-center transition-all in-out-sumday duration-300`

    let sizeClasses = ``
    switch (size) {
      case "s":
        sizeClasses = `h-8 px-4 type-label-m`
        break
      case "m":
        sizeClasses = `h-[40px] px-[14px] type-label-m`
        break
      case "l":
        sizeClasses = `h-9 px-7 type-body-l`
        break
      case "xl":
        sizeClasses = `h-10 px-7 md:px-[56px] type-title-xs`
        break
    }

    let styleClasses = ``
    switch (style) {
      case "primary":
        styleClasses = `bg-text text-invert hover:bg-accent active:bg-text disabled:bg-ruler`
        break
      case "accent":
        styleClasses = `bg-accent text-invert hover:bg-text active:bg-text disabled:bg-ruler`
        break
      case "accentAlt":
        styleClasses = `bg-accent text-invert hover:bg-invert hover:text-text active:bg-invert active:text-text disabled:bg-ruler`
        break
      case "secondary":
        styleClasses = `bg-bg text-accent shadow-button shadow-accent hover:bg-accent hover:text-invert active:bg-accent active:text-invert disabled:text-ruler disabled:shadow-ruler disabled:hover:bg-bg`
        break
      case "inverted":
        styleClasses = `bg-invert text-text hover:bg-invertAlt active:bg-invertAlt disabled:bg-invert disabled:text-invertAlt`
      case "invertedAccent":
        styleClasses = `bg-bg text-text hover:bg-invertAlt active:bg-text disabled:bg-ruler`
        break
      case "transparentPrimary":
        styleClasses = `bg-transparent shadow-button shadow-text text-text hover:bg-text active:bg-primary disabled:bg-invert disabled:text-invertAlt hover:text-bg`
        break
      case "invertedHidden":
        styleClasses = `text-text bg-bgAlt hover:bg-invertAlt`
    }

    let roundedClasses = rounded ? `rounded-full` : `rounded-button`

    return `${commonClasses} ${sizeClasses} ${roundedClasses} ${styleClasses}`
  }, [])

  const iconClasses = useMemo(() => {
    let classes = ``
    switch (size) {
      case "s":
        classes = `w-4 h-4`
        break
      case "m":
        classes = `w-[14px] h-[14px]`
        break
      case "l":
        classes = `w-5 h-5`
        break
      case "xl":
        classes = `w-5 h-5`
        break
    }

    return classes
  }, [])

  if (href)
    return (
      <Link className={classes} href={href} target={target ?? undefined}>
        {iconLeft && <Icon name={iconLeft} className={iconClasses} />}
        {children}
        {iconRight && <Icon name={iconRight} className={iconClasses} />}
      </Link>
    )

  return (
    <button className={classes} onClick={onClick ?? undefined} disabled={disabled} type={type}>
      {iconLeft && <Icon name={iconLeft} className={iconClasses} />}
      {children}
      {iconRight && <Icon name={iconRight} className={iconClasses} />}
    </button>
  )
}

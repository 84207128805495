import { useState, useEffect } from "react"

export const useBannerVisibility = (key: string, defaultValue = true) => {
  const [show, setShow] = useState<boolean | null>(null)

  useEffect(() => {
    const stored = sessionStorage.getItem(key)
    if (stored === null) {
      // Key not present, use default
      setShow(defaultValue)
      sessionStorage.setItem(key, String(defaultValue))
    } else {
      setShow(stored === "true")
    }
  }, [key, defaultValue])

  useEffect(() => {
    if (show !== null) {
      sessionStorage.setItem(key, String(show))
    }
  }, [key, show])

  return [show, setShow] as const
}
